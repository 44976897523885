.main {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.buttonContainer {
  text-align: right;
}

.tableContainer {
  overflow: auto;
  flex: 1;
  padding: 12px;
  font-size: 0.9em;
}

.tableContainer th,
.tableContainer td {
  padding: 3px 6px;
}

.padding10 {
  padding: 10px;

  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 1px none #9e9b9e;
}
.padding20 {
  -webkit-box-shadow: 10px 10px 24px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 24px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 24px -4px rgba(0, 0, 0, 0.75);
}
.padding5 {
  padding: 5px;
  box-shadow: 11px 10px 48px 0px rgba(0, 0, 0, 0.75);
}

.main {
  text-align: center;
}

.box {
  display: inline-block;
  position: fixed;
  z-index: 10;
  top: 0;
  color: white;
  font-size: 0.8em;
  padding: 1px 4px;
}

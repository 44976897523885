.main {
  display: flex;
  align-items: center;
  padding: 8px;
}

.label {
  padding-right: 12px;
  font-size: 0.9em;
  opacity: 0.5;
  padding: 2px 6px;
}

.container {
  flex: 1;
  display: flex;
  align-items: center;
}

.slider {
  width: 60%;
}

.rangeValue {
  padding: 0 6px;
}
